
// :root {
//   --text-base: 1rem;
//   --text-scale: 1.414;
//   --text-xs:  calc(var(--text-base) / var(--text-scale) / var(--text-scale));
//   --text-sm:  calc(var(--text-base) / var(--text-scale));
//   --text-md:  calc(var(--text-base) * var(--text-scale));
//   --text-lg:  calc(var(--text-base) * var(--text-scale) * var(--text-scale));
//   --text-xl:  calc(var(--text-base) * var(--text-scale) * var(--text-scale) * var(--text-scale));
//   --text-xxl: calc(var(--text-base) * var(--text-scale) * var(--text-scale) * var(--text-scale) * var(--text-scale));
// }

@mixin text($prefix: '') {

  @if ($prefix != '') {
    $prefix: '#{$prefix}\\:';
  }

  // .#{$prefix}f-xs  { font-size: var(--text-xs) }
  // .#{$prefix}f-sm  { font-size: var(--text-sm) }
  // .#{$prefix}f-md  { font-size: var(--text-md) }
  // .#{$prefix}f-lg  { font-size: var(--text-lg) }
  // .#{$prefix}f-xl  { font-size: var(--text-xl) }
  // .#{$prefix}f-xxl { font-size: var(--text-xxl) }

  .#{$prefix}text-left   { text-align: left }
  .#{$prefix}text-right  { text-align: right }
  .#{$prefix}text-center { text-align: center }
}

@include text();

@each $prefix, $query in $breakpoints {
  @media ($query) {
    @include text($prefix);
  }
}

.f-0 { font-size: 0 }

.f-body     { font-family: $font-body }
.f-headuing { font-family: $font-heading }

.f-100 { font-weight: 100 }
.f-200 { font-weight: 200 }
.f-300 { font-weight: 300 }
.f-400 { font-weight: 400 }
.f-500 { font-weight: 500 }
.f-600 { font-weight: 600 }
.f-700 { font-weight: 700 }
.f-800 { font-weight: 800 }
.f-900 { font-weight: 900 }

.f-antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.f-subpixel {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.ls-0        { letter-spacing: 0 }
.ls-tight    { letter-spacing: -0.05em }
.ls-loose    { letter-spacing: 0.05em }

.lh-0        { line-height: 0 }
.lh-1        { line-height: 1 }
.lh-tight    { line-height: 1.25 }
.lh-normal   { line-height: 1.5; line-height: var(--line-height, 1.5) }
.lh-loose    { line-height: 2 }

.italic      { font-style: italic }
.lowercase   { text-transform: lowercase }
.uppercase   { text-transform: uppercase }
.underline   { text-decoration: underline }
.no-select   { user-select: none }
.no-wrap     { white-space: nowrap }

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}