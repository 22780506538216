
$column-count: 12;
$column-gap: 18px;
$column-margin: $column-gap / 2;

@mixin cols($prefix: '', $count: $column-count, $gap: $column-gap, $margin: $column-margin) {

  @if ($prefix != '') {
    $prefix: '#{$prefix}\\:';
  }

  @for $i from 1 through $count {
    $width: percentage($i / $count);

    .#{$prefix}col-#{$i} {
      width: calc(#{$width} - #{$gap});
      width: calc(#{$width} - var(--column-gap));
    }
    .#{$prefix}col-offset-#{$i} {
      margin-left: calc(#{$width} + #{$margin});
      margin-left: calc(#{$width} + var(--column-margin));
    }
  }
}

.columns {
  --column-gap: #{$column-gap};
  --column-margin: calc(var(--column-gap) / 2);

  display: flex;
  flex-wrap: wrap;
  margin-left: -#{$column-margin};
  margin-left: calc(-1 * var(--column-margin));
  margin-right: -#{$column-margin};
  margin-right: calc(-1 * var(--column-margin));
  margin-bottom: -#{$column-gap};
  margin-bottom: calc(-1 * var(--column-gap));
}

[class*="col-"] {
  width: calc(100% - #{$column-gap});
  width: calc(100% - var(--column-gap));
  margin-left: #{$column-margin};
  margin-left: var(--column-margin);
  margin-right: #{$column-margin};
  margin-right: var(--column-margin);
  margin-bottom: #{$column-gap};
  margin-bottom: var(--column-gap);
}

@include cols();

@each $prefix, $query in $breakpoints {
  @media ($query) {
    @include cols($prefix);
  }
}