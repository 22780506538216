
@mixin flexbox($prefix: '') {

  @if ($prefix != '') {
    $prefix: '#{$prefix}\\:';
  }

  .#{$prefix}flex-row         { display: flex; flex-direction: row; }
  .#{$prefix}flex-row-reverse { flex-direction: row-reverse }
  .#{$prefix}flex-col         { display: flex; flex-direction: column; }
  .#{$prefix}flex-col-reverse { flex-direction: column-reverse }

  .#{$prefix}justify-start   { justify-content: flex-start }
  .#{$prefix}justify-end     { justify-content: flex-end }
  .#{$prefix}justify-center  { justify-content: center }
  .#{$prefix}justify-between { justify-content: space-between }
  .#{$prefix}justify-around  { justify-content: space-around }
  .#{$prefix}justify-evenly  { justify-content: space-evenly }

  .#{$prefix}items-start     { align-items: flex-start }
  .#{$prefix}items-end       { align-items: flex-end }
  .#{$prefix}items-center    { align-items: center }
  .#{$prefix}items-stretch   { align-items: stretch }

  .#{$prefix}self-start      { align-self: flex-start }
  .#{$prefix}self-end        { align-self: flex-end }
  .#{$prefix}self-center     { align-self: center }
  .#{$prefix}self-stretch    { align-self: stretch }

  .#{$prefix}content-start   { align-content: flex-start }
  .#{$prefix}content-end     { align-content: flex-end }
  .#{$prefix}content-center  { align-content: center }
  .#{$prefix}content-stretch { align-content: stretch }
  .#{$prefix}content-between { align-content: space-between }
  .#{$prefix}content-around  { align-content: space-around }
  .#{$prefix}content-evenly  { align-content: space-evenly }

  .#{$prefix}flex-1	         { flex: 1 }
  .#{$prefix}flex-1-0-auto	 { flex: 1 0 auto }
  .#{$prefix}flex-initial	   { flex: initial }
  .#{$prefix}flex-wrap	     { flex-wrap: wrap }
  .#{$prefix}flex-auto	     { flex: auto }
  .#{$prefix}flex-none	     { flex: none }
  .#{$prefix}flex-grow	     { flex-grow: 1 }
  .#{$prefix}flex-no-grow	   { flex-grow: 0 }
  .#{$prefix}flex-shrink	   { flex-shrink: 1 }
  .#{$prefix}flex-no-shrink	 { flex-shrink: 0 }

  .#{$prefix}order-first     { order: -1 }
  .#{$prefix}order-last      { order: 9999 }
}

@include flexbox();

@each $prefix, $query in $breakpoints {
  @media ($query) {
    @include flexbox($prefix);
  }
}