
::-moz-selection {
  background: rgba($color-brown-light,0.2);
}
::selection {
  background: rgba($color-brown-light,0.2);
}

body {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  font-family: $font-body;
  font-weight: 400;
  line-height: 1.4;
  color: $color-text;
  background-color: $color-blue-dark;
}
#root {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  background-color: $color-bg;
  background-image: url('../../images/topography-dark.svg');
  background-position: 50% 0;
  background-size: 500px auto;
}
.is-home #root {
  background-size: 600px auto;
}

p {
  margin-bottom: 1.625em;
}
p:last-child {
  margin-bottom: 0;
}
a {
  color: $color-link;
  line-height: inherit;
  font-weight: 500;
  text-decoration: none;
  outline: 0;
}
a:hover {
  color: $color-link-hover;
}
strong, b {
  font-weight: 700;
  line-height: inherit;
}
em, i {
  font-style: italic;
  line-height: inherit;
}
small, .small {
  font-size: 80%;
}
hr {
  clear: both;
  width: 100%;
  height: 1px;
  margin: 2.5rem 0;
  line-height: 0;
  border: none;
  background-color: $color-line;
}