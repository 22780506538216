
:root {
  // https://www.modularscale.com/
  --spacing-unit: 0.75rem;
  --spacing-scale: 1.667;
  --spacing-1: var(--spacing-unit);
  --spacing-2: calc(var(--spacing-1) * var(--spacing-scale));
  --spacing-3: calc(var(--spacing-2) * var(--spacing-scale));
  --spacing-4: calc(var(--spacing-3) * var(--spacing-scale));
  --spacing-5: calc(var(--spacing-4) * var(--spacing-scale));
}

@mixin spacing($prefix: '') {

  @if ($prefix != '') {
    $prefix: '#{$prefix}\\:';
  }

  @for $i from 0 through 5 {

    $value: 0;
    @if ($i != 0) { $value: var(--spacing-#{$i}); }

    $fallback: calc(#{$i} * 1rem);

    // margin

    .#{$prefix}m-#{$i}  {
      margin: $fallback;
      margin: $value;
    }
    .#{$prefix}mt-#{$i} {
      margin-top: $fallback;
      margin-top: $value;
    }
    .#{$prefix}mr-#{$i} {
      margin-right: $fallback;
      margin-right: $value;
    }
    .#{$prefix}mb-#{$i} {
      margin-bottom: $fallback;
      margin-bottom: $value;
    }
    .#{$prefix}ml-#{$i} {
      margin-left: $fallback;
      margin-left: $value;
    }
    .#{$prefix}mx-#{$i} {
      margin-left: $fallback;
      margin-left: $value;
      margin-right: $fallback;
      margin-right: $value;
    }
    .#{$prefix}my-#{$i} {
      margin-top: $fallback;
      margin-top: $value;
      margin-bottom: $fallback;
      margin-bottom: $value;
    }

    // padding

    .#{$prefix}p-#{$i}  {
      padding: $fallback;
      padding: $value;
    }
    .#{$prefix}pt-#{$i} {
      padding-top: $fallback;
      padding-top: $value;
    }
    .#{$prefix}pr-#{$i} {
      padding-right: $fallback;
      padding-right: $value;
    }
    .#{$prefix}pb-#{$i} {
      padding-bottom: $fallback;
      padding-bottom: $value;
    }
    .#{$prefix}pl-#{$i} {
      padding-left: $fallback;
      padding-left: $value;
    }
    .#{$prefix}px-#{$i} {
      padding-left: $fallback;
      padding-left: $value;
      padding-right: $fallback;
      padding-right: $value;
    }
    .#{$prefix}py-#{$i} {
      padding-top: $fallback;
      padding-top: $value;
      padding-bottom: $fallback;
      padding-bottom: $value;
    }
  }
}

@include spacing();

@each $prefix, $query in $breakpoints {
  @media ($query) {
    @include spacing($prefix);
  }
}

.m-auto  {
  margin: auto
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}