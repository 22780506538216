
.block        { display: block }
.flex         { display: flex }
.inline-block { display: inline-block }
.inline-flex  { display: inline-flex }
.hide         { display: none }
.show         { display: inherit }

@each $prefix, $query in $breakpoints {

  @media ($query) {

    .#{$prefix}\:block        { display: block }
    .#{$prefix}\:flex         { display: flex }
    .#{$prefix}\:inline-block { display: inline-block }
    .#{$prefix}\:inline-flex  { display: inline-flex }
    .#{$prefix}\:hide         { display: none }
    .#{$prefix}\:show         { display: inherit }
  }
}

.hidden {
  position: absolute;
  height: 1px;
  width: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
}
.invisible {
  visibility: hidden;
}

.scroll-x {
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.scroll-y {
  overflow-y: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.cursor-pointer { cursor: pointer !important }
.cursor-default { cursor: default !important }

.no-overflow-x { overflow-x: hidden }
.no-overflow-y { overflow-y: hidden }
.no-overflow   { overflow: hidden }
.no-outline    { outline: none }
.no-pointer    { pointer-events: none }
