
$font-body: 'Barlow', sans-serif;
$font-heading: 'Martel', serif;

$container-width: 1000px;
$container-padding: 20px;

$xs: 375px;
$sm: 600px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;

$breakpoints: (
  'xs': 'min-width: #{$xs}',
  'sm': 'min-width: #{$sm}',
  'md': 'min-width: #{$md}',
  'lg': 'min-width: #{$lg}',
  'xl': 'min-width: #{$xl}',
);

$color-red: #950F00;
$color-orange: #F35500;
$color-orange-dark: #E1551A;
$color-orange-light: #FF6000;
$color-blue: #007D9B;
$color-blue-dark: #202930;
$color-brown-dark: #3D2C11;
$color-brown-light: #887F76;
$color-tan: #D9D1C3;
$color-bg: #FCFCFA;
$color-text: $color-brown-light;
$color-line: rgba($color-text,0.25);
$color-link: $color-orange;
$color-link-hover: $color-orange-dark;
$color-error: $color-red;

$colors: ();

:root {

  --container-width: #{$container-width};
  --container-padding: #{$container-padding};

  font-size: 15px;

  @media (min-width: $xs) {
    font-size: 16px;
  }
  @media (min-width: $md) {
    font-size: 18px;
    // --container-padding: 30px;
  }
  @media (min-width: $lg) {
    font-size: 20px;
  }
}