
.static   { position: static }
.relative { position: relative }
.absolute { position: absolute }
.fixed    { position: fixed }
.sticky   { position: -webkit-sticky; position: sticky }

.pin-t    { top: 0 }
.pin-r    { right: 0 }
.pin-b    { bottom: 0 }
.pin-l    { left: 0 }

.pin-x {
  @extend .pin-l;
  @extend .pin-r;
}
.pin-y {
  @extend .pin-t;
  @extend .pin-b;
}
.pin {
  @extend .pin-x;
  @extend .pin-y;
}

@each $prefix, $query in $breakpoints {

  @media ($query) {

    .#{$prefix}\:static   { position: static }
    .#{$prefix}\:relative { position: relative }
    .#{$prefix}\:absolute { position: absolute }
    .#{$prefix}\:fixed    { position: fixed }
    .#{$prefix}\:sticky   { position: -webkit-sticky; position: sticky }

    .#{$prefix}\:pin-t    { top: 0; bottom: auto; }
    .#{$prefix}\:pin-r    { right: 0; left: auto; }
    .#{$prefix}\:pin-b    { bottom: 0; top: auto; }
    .#{$prefix}\:pin-l    { left: 0; right: auto; }
    .#{$prefix}\:pin-x    { left: 0; right: 0; }
    .#{$prefix}\:pin-y    { top: 0; bottom: 0; }
    .#{$prefix}\:pin      { top: 0; right: 0; bottom: 0; left: 0; }
  }
}

.fit-cover   { object-fit: cover }
.fit-contain { object-fit: contain }

.center-x {
  left: 50%;
  transform: translateX(-50%);
}
.center-y {
  top: 50%;
  transform: translateY(-50%);
}
.center {
  @extend .center-x;
  @extend .center-y;
  transform: translate(-50%,-50%);
}

.z-0  { z-index: 0 }
.z-1  { z-index: 1 }
.z-2  { z-index: 2 }
.z-3  { z-index: 3 }
.z-4  { z-index: 4 }
.z-5  { z-index: 5 }
.z-6  { z-index: 6 }
.z-7  { z-index: 7 }
.z-8  { z-index: 8 }
.z-9  { z-index: 9 }
.z-10 { z-index: 100 }
