#root.unsupported {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: $font-heading;
  line-height: 1.4;
  background-color: $color-orange;

  p {
    max-width: 880px;
    padding: 1em;
    font-size: 2rem;
    text-align: center;
    color: #FFF;
  }
  a {
    text-decoration: none;
    color: $color-red;
  }
}