.content {

  > *:first-child { margin-top: 0; }
  > *:last-child  { margin-bottom: 0; }

  h1 {
    margin-bottom: 0.5em;
  }
  h2 {
    margin-bottom: 0.25em;
  }
  h3 {
    margin-bottom: 0.25em;
  }
  h3 {
    margin-bottom: 0.25em;
  }

  p + h1,
  p + h2,
  p + h3,
  p + h4 {
    margin-top: 1.5em;
  }

  ul, ol {
    margin: 2em 0 2em 2em;
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
  li {
    margin-bottom: 0.5em;
  }
}

.lead {
  font-size: 1.3rem;
  font-weight: 300;
  line-height: 1.33;
}
.cta-link {
  font-weight: 600;
}
.footnote {
  font-size: 0.825em;
  opacity: 0.65;
}
.footnote:last-child {
  margin-top: 3em;
}