
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5 {
  font-family: $font-heading;
  font-weight: 700;
  line-height: 1.2;
  color: $color-blue-dark;
}

h1, .h1 {
  font-size: 2.33rem;
  letter-spacing: -0.02em;
}
h2, .h2 {
  font-size: 1.5rem;
}
h3, .h3 {
  font-size: 1.125rem;
  line-height: 1.4;
}
h4, .h4 {
  font-size: 1.0625rem;
  font-family: $font-body;
  letter-spacing: 0.01em;
}
h5, .h5 {
  font-size: 0.95rem;
  font-family: $font-body;
  font-weight: 600;
}