
:root {
  --width-1: 24rem;
  --width-2: 32rem;
  --width-3: 48rem;
  --width-4: 64rem;
}

@mixin sizing($prefix: '') {

  @if ($prefix != '') {
    $prefix: '#{$prefix}\\:';
  }

  .#{$prefix}w-auto { width: auto }
  .#{$prefix}w-1\/2 { width: 50% }
  .#{$prefix}w-1\/3 { width: 33.333% }
  .#{$prefix}w-2\/3 { width: 66.667% }
  .#{$prefix}w-1\/4 { width: 25% }
  .#{$prefix}w-3\/4 { width: 75% }
  .#{$prefix}w-1\/5 { width: 20% }
  .#{$prefix}w-2\/5 { width: 40% }
  .#{$prefix}w-3\/5 { width: 60% }
  .#{$prefix}w-4\/5 { width: 80% }
  .#{$prefix}w-1\/6 { width: 16.667% }
  .#{$prefix}w-5\/6 { width: 83.333% }
  .#{$prefix}w-100  { width: 100% }
  .#{$prefix}h-100  { height: 100% }

  .#{$prefix}vw-100     { width: 100vw }
  .#{$prefix}vh-100     { height: 100vh }

  .#{$prefix}min-vw-100 { min-width: 100vw }
  .#{$prefix}min-vh-100 { min-height: 100vh }

  .#{$prefix}max-w-1    { max-width: var(--width-1) }
  .#{$prefix}max-w-2    { max-width: var(--width-2) }
  .#{$prefix}max-w-3    { max-width: var(--width-3) }
  .#{$prefix}max-w-4    { max-width: var(--width-4) }
  .#{$prefix}max-w-100  { max-width: 100% }
}

@include sizing();

@each $prefix, $query in $breakpoints {
  @media ($query) {
    @include sizing($prefix);
  }
}

.fill-x {
  width: 100%
}
.fill-y {
  height: 100%
}
.fill {
  @extend .fill-x;
  @extend .fill-y;
}