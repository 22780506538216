
:root {
  --border-width: 1px;
  --border-radius: 5px;
}

.b {
  border-style: solid;
  border-width: var(--border-width);
}
.b-t {
  border-top-style: solid;
  border-top-width: var(--border-width);
}
.b-r {
  border-right-style: solid;
  border-right-width: var(--border-width);
}
.b-b {
  border-bottom-style: solid;
  border-bottom-width: var(--border-width);
}
.b-l {
  border-left-style: solid;
  border-left-width: var(--border-width);
}

.b-round   { border-radius: var(--border-radius) }
.b-circle  { border-radius: 50% }
.no-border { border: 0 }