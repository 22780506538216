*, *:before, *:after {
  box-sizing: border-box;
}
html {
  height: 100%;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html, body, h1, h2, h3, h4, h5, h6, p, ol, ul, li, dl, dt, dd, button, blockquote, figure, fieldset, legend {
  margin: 0;
  padding: 0;
}
h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}
article, aside, audio, canvas, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary, video {
  display: block;
}
template, [hidden] {
  display: none !important;
}
img, embed, iframe, object, audio, video {
  max-width: 100%;
  height: auto;
  margin: 0;
  border: 0;
}
ol, ul {
  list-style: none;
}
button, input, select, textarea {
  margin: 0;
  font-size: inherit;
  font-family: inherit;
}
input:not([type="checkbox"]):not([type="radio"]),
button,
select,
textarea {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
fieldset {
  border: 0;
}
textarea {
  overflow: auto;
  resize: vertical;
}
button {
  border: 0;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
}
button::-moz-focus-inner, input::-moz-focus-inner {
  padding: 0;
  border: 0;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="search"] {
  -webkit-appearance: textfield;
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button { -webkit-appearance: none; }
}