
.pad-x {
  padding-left: $container-padding;
  padding-left: var(--container-padding);
  padding-right: $container-padding;
  padding-right: var(--container-padding);
}
.pad-y {
  padding-top: $container-padding;
  padding-top: var(--container-padding);
  padding-bottom: $container-padding;
  padding-bottom: var(--container-padding);
}
.pad {
  @extend .pad-x;
  @extend .pad-y;
}
.container {
  width: 100%;
  max-width: $container-width;
  max-width: var(--container-width);
  margin-left: auto;
  margin-right: auto;
  @extend .pad-x;
}
.main {
  flex: 1 0 auto;
}