

.input, textarea, select {
  font-size: 1.1rem;
  font-family: $font-body;
  font-weight: 500;
  padding: 0.625em;
  color: #000;
  border: rgba($color-brown-light,0.25) 1px solid;
  border-radius: 0;
  transition: all 0.125s;
  -webkit-font-smoothing: antialiased;

  &:focus {
    border-color: $color-orange;
  }
}

.error {
  color: $color-error;
}

.field {
  margin-bottom: 0.625rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.label {
  display: block;
  font-size: .9rem;
  font-weight: 600;
  margin-bottom: 0.33em;
  color: $color-brown-dark;

  &:last-child {
    margin-bottom: 0;
  }
}

.input {
  width: 100%;
  background-color: #FFF;

  .has-error & {
    border-color: $color-error;
    box-shadow: 0 0 0 0.15em rgba($color-orange,0.15);
  }
  @include placeholder {
    font-weight: 400;
    color: $color-text;
  }
}

input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  display: flex;
  align-items: flex-start;

  + label {
    @include no-select;
    display: block;
    position: relative;
    padding-left: 1.625rem;
    font-size: 0.95rem;
    line-height: 1.3;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
  + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0.125em;
    width: 1rem;
    height: 1rem;
    background-color: #FFF;
    border: rgba($color-brown-light,0.3) 1px solid;
    transition: border-color 0.125s, background-color 0.125s;
  }
  &:focus + label:before {
    border-color: $color-orange;
  }
  &:checked + label:before {
    border-color: $color-orange;
    background-color: $color-orange;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23FFF' d='M186.3 339.9L96 249.45l-32 30.5L186.3 402 448 140.5 416 110z'/%3E%3C/svg%3E");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 90% auto;
  }
}

select {
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 1.5;
  border: rgba($color-brown-light,0.3) 1px solid;
  background-color: #FFF;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'%3E%3Cpath fill='%23887F76' d='M16.29 4.3a1 1 0 1 1 1.41 1.42l-8 8a1 1 0 0 1-1.41 0l-8-8A1 1 0 1 1 1.7 4.3l7.29 7.29z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.75em top 50%;
  background-size: 0.75em 0.75em;

  .has-error & {
    border-color: $color-error;
    box-shadow: 0 0 0 0.15em rgba($color-orange,0.15);
  }
}

.help {
  display: block;
  font-size: 0.75rem;
  margin-top: 0.25rem;

  .has-error & {
    color: $color-error;
  }
}