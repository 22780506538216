
// TODO: determine specific font weights used and comment out all others!

// -----------------------------------------------------------------------
// Barlow
// =======================================================================

// @font-face {
//   font-family: 'Barlow';
//   font-weight: 100;
//   font-style: normal;
//   src: url('../../fonts/barlow-100.woff2') format('woff2'),
//        url('../../fonts/barlow-100.woff') format('woff');
// }
// @font-face {
//   font-family: 'Barlow';
//   font-weight: 200;
//   font-style: normal;
//   src: url('../../fonts/barlow-200.woff2') format('woff2'),
//        url('../../fonts/barlow-200.woff') format('woff');
// }
@font-face {
  font-family: 'Barlow';
  font-weight: 300;
  font-style: normal;
  src: url('../../fonts/barlow-300.woff2') format('woff2'),
       url('../../fonts/barlow-300.woff') format('woff');
}
@font-face {
  font-family: 'Barlow';
  font-weight: 400;
  font-style: normal;
  src: url('../../fonts/barlow-400.woff2') format('woff2'),
       url('../../fonts/barlow-400.woff') format('woff');
}
@font-face {
  font-family: 'Barlow';
  font-weight: 500;
  font-style: normal;
  src: url('../../fonts/barlow-500.woff2') format('woff2'),
       url('../../fonts/barlow-500.woff') format('woff');
}
@font-face {
  font-family: 'Barlow';
  font-weight: 600;
  font-style: normal;
  src: url('../../fonts/barlow-600.woff2') format('woff2'),
       url('../../fonts/barlow-600.woff') format('woff');
}
@font-face {
  font-family: 'Barlow';
  font-weight: 700;
  font-style: normal;
  src: url('../../fonts/barlow-700.woff2') format('woff2'),
       url('../../fonts/barlow-700.woff') format('woff');
}
// @font-face {
//   font-family: 'Barlow';
//   font-weight: 800;
//   font-style: normal;
//   src: url('../../fonts/barlow-800.woff2') format('woff2'),
//        url('../../fonts/barlow-800.woff') format('woff');
// }
// @font-face {
//   font-family: 'Barlow';
//   font-weight: 900;
//   font-style: normal;
//   src: url('../../fonts/barlow-900.woff2') format('woff2'),
//        url('../../fonts/barlow-900.woff') format('woff');
// }

// -----------------------------------------------------------------------
// Martel
// =======================================================================

// @font-face {
//   font-family: 'Martel';
//   font-weight: 200;
//   font-style: normal;
//   src: url('../../fonts/martel-200.woff2') format('woff2'),
//        url('../../fonts/martel-200.woff') format('woff');
// }
// @font-face {
//   font-family: 'Martel';
//   font-weight: 300;
//   font-style: normal;
//   src: url('../../fonts/martel-300.woff2') format('woff2'),
//        url('../../fonts/martel-300.woff') format('woff');
// }
// @font-face {
//   font-family: 'Martel';
//   font-weight: 400;
//   font-style: normal;
//   src: url('../../fonts/martel-400.woff2') format('woff2'),
//        url('../../fonts/martel-400.woff') format('woff');
// }
// @font-face {
//   font-family: 'Martel';
//   font-weight: 600;
//   font-style: normal;
//   src: url('../../fonts/martel-600.woff2') format('woff2'),
//        url('../../fonts/martel-600.woff') format('woff');
// }
@font-face {
  font-family: 'Martel';
  font-weight: 700;
  font-style: normal;
  src: url('../../fonts/martel-700.woff2') format('woff2'),
       url('../../fonts/martel-700.woff') format('woff');
}
// @font-face {
//   font-family: 'Martel';
//   font-weight: 800;
//   font-style: normal;
//   src: url('../../fonts/martel-800.woff2') format('woff2'),
//        url('../../fonts/martel-800.woff') format('woff');
// }
// @font-face {
//   font-family: 'Martel';
//   font-weight: 900;
//   font-style: normal;
//   src: url('../../fonts/martel-900.woff2') format('woff2'),
//        url('../../fonts/martel-900.woff') format('woff');
// }
